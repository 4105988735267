// src/components/About.js
import React from 'react';
import './About.css';

const About = () => {
  return (
    <section id="about" className="about-section">
      <div className="about-content">
        <h2 className="services-title">About me</h2>
        <p className="about-text">
          I am a passionate web developer with a love for creating sleek and functional designs. 
          My journey began with an interest in technology and design, and I’ve developed a unique style 
          that blends creativity with efficiency. I focus on writing clean, maintainable code and bringing ideas to life 
          through innovative solutions.
        </p>
        <p className="about-text">
          As a professional, I aim to continuously grow by embracing new challenges, learning the latest technologies, 
          and contributing to projects that make an impact. My goal is to blend form and function in a way that adheres to 
          the Bauhaus principle of "less is more," always prioritizing the user experience.
        </p>
      </div>
    </section>
  );
};

export default About;
