import React, { useState } from 'react';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [isSending, setIsSending] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [isError, setIsError] = useState(false); // Añadir estado para controlar si es error

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true); // Mostrar spinner
    setFeedback(null); // Reiniciar el mensaje de feedback
    setIsError(false); // Reiniciar el estado de error

    try {
      const response = await fetch('https://fsawka.website/e/index.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (result.success) {
        setFeedback('Your message has been sent successfully! We will get in touch soon.');
        setIsError(false); // Mensaje de éxito
        // Limpiar los campos después del envío exitoso
        setFormData({
          name: '',
          email: '',
          message: ''
        });
      } else {
        setFeedback('Failed to send your message. Please try again later.');
        setIsError(true); // Indicar que es un error
      }
    } catch (error) {
      setFeedback('An error occurred. Please try again.');
      setIsError(true); // Indicar que es un error
    }

    setIsSending(false); // Ocultar spinner
  };

  return (
    <section id="contact" className="contact-section">
      <div className="contact-container">
        <h2 className="services-title">Get In Touch</h2>
        <p className="contact-description">
          I'd love to hear from you! Whether it's a project, job opportunity, or just a chat, feel free to reach out.
        </p>

        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Your Name"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Your Email"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              rows="4"
              value={formData.message}
              onChange={handleInputChange}
              placeholder="Your Message"
              required
            ></textarea>
          </div>

          <button type="submit" className="submit-btn" disabled={isSending}>
            {isSending ? <span className="spinner"></span> : 'Send Message'}
          </button>
        </form>

        {/* Mostrar el feedback con clase dependiendo de si es error o éxito */}
        {feedback && (
          <p className={`feedback-message ${isError ? 'error-message' : 'success-message'}`}>
            {feedback}
          </p>
        )}
      </div>
    </section>
  );
};

export default Contact;
