// src/components/Footer.js
import React, { useEffect } from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutubeSquare } from 'react-icons/fa';
import './Footer.css'; // Asegúrate de importar el archivo CSS

const Footer = () => {
  const handleMouseMove = (event) => {
    const { clientX, clientY } = event;
    const { innerWidth, innerHeight } = window;

    // Calcula el porcentaje de la posición del mouse
    const x = (clientX / innerWidth) * 100;
    const y = (clientY / innerHeight) * 100;

    // Actualiza el fondo del footer
    document.querySelector('.footer').style.background = `radial-gradient(circle at ${x}% ${y}%, #FFD700, #333)`;
  };

  useEffect(() => {
    // Añade el evento de mousemove
    window.addEventListener('mousemove', handleMouseMove);

    // Limpia el evento en el desmontaje
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3>Contact Us</h3>
          <p>Email: facu@fsawka.website</p>
          <p>Phone: (351) 2571888</p>
        </div>
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="https://www.youtube.com/@facundosawka5852"><FaYoutubeSquare /></a>
            <a href="https://www.instagram.com/facu.sawka/"><FaInstagram /></a>
            <a href="https://ar.linkedin.com/in/facundo-sawka-aba19a104"><FaLinkedin /></a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Facu Sawka. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
