import React, { useState, useEffect } from 'react';
import './Home.css';

const Home = () => {
  const [showGif, setShowGif] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setShowGif(true);
      } else {
        setShowGif(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Función para hacer scroll hacia la sección "About Me"
  const scrollToAboutMe = () => {
    const aboutMeSection = document.getElementById('about');
    if (aboutMeSection) {
      aboutMeSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section id="home" className="home-container">
      <div className="intro">
        <div className="profile-container">
          <img
            src={showGif ? 'https://fsawka.website/i/fs.gif' : 'https://fsawka.website/i/fs.jpeg'}
            alt="Profile"
            className="profile-image"
          />
        </div>
        <h1 className="home-title">Facu Sawka</h1>
        <p className="home-description">
          Discover my projects, services, and the journey of a Senior Developer.
        </p>

        {/* Botón con flecha hacia abajo */}
        <div className="scroll-button-container">
          <button className="scroll-button" onClick={scrollToAboutMe}>
            <span className="arrow">&#x2193;</span> {/* Flecha hacia abajo */}
          </button>
        </div>
      </div>
    </section>
  );
};

export default Home;
