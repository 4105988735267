// src/components/Services.js
import React from 'react';
import './Services.css';

const Services = () => {
  return (
    <section id="services" className="services-section">
      <h2 className="services-title">What I Offer</h2>
      <div className="services-container">
        <div className="service-item">
          <div className="service-icon">
            <i className="fas fa-code"></i> {/* Ícono que simboliza programación */}
          </div>
          <h3>Full-Stack Development</h3>
          <p>I create complete web solutions from front-end to back-end, using modern frameworks and best practices.</p>
        </div>
        <div className="service-item">
          <div className="service-icon">
            <i className="fas fa-chart-line"></i> {/* Ícono que simboliza rendimiento */}
          </div>
          <h3>Performance Optimization</h3>
          <p>Boost your web app's speed and efficiency, ensuring a smooth user experience.</p>
        </div>
        <div className="service-item">
          <div className="service-icon">
            <i className="fas fa-cogs"></i> {/* Ícono que simboliza arquitectura */}
          </div>
          <h3>Technical Consultancy</h3>
          <p>Get expert guidance on technical architecture, ensuring scalability and robustness.</p>
        </div>

        <div className="service-item">
          <div className="service-icon">
            <i className="fas fa-sync-alt"></i> {/* Ícono que simboliza mantenimiento */}
          </div>
          <h3>Ongoing Support</h3>
          <p>Maintenance and support to keep your web applications secure and up-to-date.</p>
        </div>
      </div>
    </section>
  );
};

export default Services;
