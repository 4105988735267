import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link as ScrollLink } from 'react-scroll';
import './Navbar.css'; // Asegúrate de importar el archivo CSS adecuado

const MyNavbar = () => {
  return (
    <Navbar expand="lg" collapseOnSelect className="glass-navbar fixed-top">
      <Container>
        <Navbar.Brand>Facu Sawka</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link as={ScrollLink} to="home" spy={true} smooth={true} duration={500} className="nav-link">Home</Nav.Link>
            <Nav.Link as={ScrollLink} to="about" spy={true} smooth={true} duration={500} className="nav-link">About</Nav.Link>
            <Nav.Link as={ScrollLink} to="services" spy={true} smooth={true} duration={500} className="nav-link">Offer</Nav.Link>
            <Nav.Link as={ScrollLink} to="contact" spy={true} smooth={true} duration={500} className="nav-link">Get in</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MyNavbar;
