import React from 'react';
import MyNavbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import Portfolio from './components/Portfolio';
import Footer from './components/Footer';
import DronePilot from './components/Ai';
import Ai from './components/DronePilot';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div>
      <MyNavbar />
      <Home />
      <About />
      <Services />

      
      
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
